import Firebase from 'firebase/app'
import 'firebase/firestore'

function initFirebase () {
  //  Prod
  // Firebase.initializeApp({
  //   apiKey: 'AIzaSyBCYzXrnUG2oYl9LaUoMURILgcFtag0TOI',
  //   authDomain: 'postmantools.firebaseapp.com',
  //   databaseURL: 'https://postmantools.firebaseio.com',
  //   projectId: 'postmantools',
  //   storageBucket: 'gs://postmantools.appspot.com',
  //   messagingSenderId: '831823205399',
  //   appId: '1:831823205399:web:f6558bc9e91e12d5fab852'
  // })

  // DEV
  Firebase.initializeApp({
    apiKey: 'AIzaSyCI4vRYS6Z78GOBNCUzBVgyYF87Q-jGVgQ',
    authDomain: 'postmantools-dev-312414.firebaseapp.com',
    projectId: 'postmantools-dev-312414',
    storageBucket: 'postmantools-dev-312414.appspot.com',
    messagingSenderId: '345223914236',
    appId: '1:345223914236:web:ffd9a4dad2d52e3463ad79',
    measurementId: 'G-NMDRY4W931'
  })

  return new Promise((resolve, reject) => {
    Firebase.firestore().enablePersistence()
      .then(resolve)
      .catch(err => {
        if (err.code === 'failed-precondition') {
          reject(err)
          // Multiple tabs open, persistence can only be
          // enabled in one tab at a a time.
        } else if (err.code === 'unimplemented') {
          reject(err)
          // The current browser does not support all of
          // the features required to enable persistence
        }
      })
  })
}

export { Firebase, initFirebase }
